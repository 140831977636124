<template>
	<div class="card">
		<Toast />
        <DataView :value="products" :layout="layout" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField">
			<template #header >
                <div class="p-grid p-nogutter" >
                    <div class="p-col-10 header-sort" style="text-align: left">
                        <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Сортировка по цене" @change="onSortChange($event)"/>
                        <Dropdown v-model="sortKey" :options="sortOptionsFactory" optionLabel="label" placeholder="Производитель" @change="onSortChangeFactory($event)"/>
                    </div>
                    <div class="p-col-2 header-style" style="text-align: right">
                        <DataViewLayoutOptions v-model="layout" />
                    </div>
                </div>
			</template>

			<template #list="slotProps">
				<div class="p-col-12">
					<div class="product-list-item">
						<div class="product-list-info">
						<router-link class="router" :to="'/productCard/'+slotProps.data.id">
						<img class="product-image-list" :src="slotProps.data.image_url" :alt="slotProps.data.name"/>
						<div class="product-list-detail">
							<i class="pi pi-tag product-category-icon"></i><span class="product-category">Виски</span>
							<div class="product-name"> <b>{{slotProps.data.name}}</b> {{slotProps.data.factory}}</div>
							<span :class="'product-badge status'">Крепость {{slotProps.data.strong}} об.</span>
							<div class="product-description">Объем: {{slotProps.data.size}} л.</div>
							
						</div>
						</router-link>
						</div>
						<div class="product-list-action">
							<span class="product-price"> <b>{{slotProps.data.price}} грн.</b></span>
							<Button icon="pi pi-shopping-cart" class="p-button-sm p-button-outlined p-button-success" label="Купить" @click="adToCart(slotProps.data)"></Button>
						</div>
					</div>
				</div>
			</template>

			<template #grid="slotProps">
				<div class="p-col-12 p-md-4">
					<div class="product-grid-item card">
						<router-link :to="'/productCard/'+slotProps.data.id">
						<div class="product-grid-item-top">
							<div>
								<i class="pi pi-tag product-category-icon"></i>
								<span class="product-category">Виски</span>
							</div>
							<span :class="'product-badge status'">{{slotProps.data.factory}}</span>
						</div>
						<div class="product-grid-item-content">
							<div class="image-container">
							<img class="product-image" :src="slotProps.data.image_url" :alt="slotProps.data.name"/>
							</div>
							<div class="product-name">{{slotProps.data.name}}</div>
							<span :class="'product-badge status'">Крепость {{slotProps.data.strong}} об.</span>
							<div class="product-description">Объем: {{slotProps.data.size}} л.</div>
						</div>
						</router-link>
						<div class="product-grid-item-bottom">
							<span class="product-price">{{slotProps.data.price}} грн.</span>
							<div>
								<Button class="p-button-success p-button-outlined" icon="pi pi-shopping-cart" @click="adToCart(slotProps.data)"></Button>
								<router-view></router-view>
							</div>
						</div>
					</div>
				</div>
			</template>
		</DataView>
	</div>
</template>

<script>

import ProductService from '../service/ProductService';
import OrderService from '../service/OrderService';
import {  mapState, mapMutations } from 'vuex';

export default {
	data() {
        return {
            products: null,
            layout: 'grid',
            sortKey: null,
            sortOrder: null,
            sortField: null,
			categoryId: "",
            sortOptions: [
                {label: 'Price High to Low', value: '!price'},
                {label: 'Price Low to High', value: 'price'},
            ],
			sortOptionsFactory: [
				{label: 'Производитель А - я', value: '!factory'},
				{label: 'Производитель Я - а', value: 'factory'},
			]
        }
    },
    productService: null,
	orderService: null,
    created() {
        this.productService = new ProductService();
		this.orderService = new OrderService();
		this.orderService.order = JSON.parse(JSON.stringify(this.globalOrder));
		
    },
    mounted() {

        this.refreshProducts(this.$route.params.id);
    },
	beforeRouteUpdate(to, from, next) {
		this.refreshProducts(to.params.id);

		next();
	},

	computed: mapState({
        globalOrder: state => state.orders.globalOrder,
    }),
	methods: {
		async refreshProducts (catId) {
			this.categoryId = catId;
			
			if (this.categoryId == 0) {
				this.productService.getProducts().then(data => this.products = data);
			} else {this.products = await this.productService.getProductsByCatId(this.categoryId);}
		},

		onSortChange(event) {
		const value = event.value.value;
		const sortValue = event.value;

		if (value.indexOf('!') === 0) {
			this.sortOrder = -1;
			this.sortField = value.substring(1, value.length);
			this.sortKey = sortValue;
		}
		else {
			this.sortOrder = 1;
			this.sortField = value;
			this.sortKey = sortValue;
		}
		},

		onSortChangeFactory(event) {
		const value = event.value.value;
		const sortValue = event.value;

		if (value.indexOf('!') === 0) {
			this.sortOrder = -1;
			this.sortField = value.substring(1, value.length);
			this.sortKey = sortValue;
		}
		else {
			this.sortOrder = 1;
			this.sortField = value;
			this.sortKey = sortValue;
		}
		},

		...mapMutations([
			'setOrder',
			'setCartCount' // `this.increment()` будет вызывать `this.$store.commit('increment')`
		]),

		async adToCart(product) {
			console.log(product)
			await this.orderService.addProduct(product);
			this.setOrder(this.orderService.order);	
			this.setCartCount(this.orderService.order.total.quantity);
			this.$toast.add({severity: 'success', summary: 'Товар добавлен в корзину', detail: product.name, life: 1000});
		},
	}
}
</script>

<style lang="scss" scoped>

.header-sort {
    display: flex;
    flex-wrap: wrap;
}
.header-style {
    display: flex;
    justify-content: flex-end;
}
.p-col-12 a{
	color: #000000;
}
.product-image {
	display: block;
	width: 100%;
	height: 100%;
}
.image-container {
	width: 280px;
	height: 350px;
	padding-bottom: 20px;
}

.product-list-item {
    max-height: 150px;
	margin: 10px 0px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 1px 0px 0px rgba(0, 0, 0, 0.23);
}
.router {
	display: flex;
}
.product-list-action{
    display: flex;
	flex-direction: column;
}
.action_start{
	display: flex;
	flex-direction: column;
}
.card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.3), 0 1px 1px 0 rgba(0,0,0,.3), 0 1px 15px 0 rgba(0,0,0,.3);
    border-radius: 4px;
    margin-bottom: 2rem;
}
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.product-name {
	font-size: 1.3rem;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 2rem;
	width: 100%;

	img {
		width: 100px;
        height: 140px;
		//box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}
.p-nogutter > .p-col, .p-nogutter > [class*=p-col-] {
    display: flex;
    padding: none;
}
::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}


@media screen and (min-width: 768px) {
	.p-md-4 {
		width: 50%;

	}
}

@media screen and (min-width: 1385px) {
	.p-md-4 {
		width: 33.333%;

	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;
		.product-list-info {
			width: 100%;
		}
		img {
            width: 50px;
            height: 70px;
		}
        a {
		.product-list-detail {
            .product-name {
                font-size: 0.8rem;
            }
            span {
                font-size: 0.8rem;
            }
            .product-description {
                font-size: 0.8rem;
            }
        }
        }
		.product-price {
			align-self: center;
            font-size: 0.8rem;
		}

		.product-list-action {
			margin-top: 0.2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
	.p-nogutter > .p-col, .p-nogutter > [class*=p-col-] {
	justify-content: center;
	}
	.image-container {
			width: 170px;
			height: 220px;
			margin: 0px auto;
	}
	.product-grid-item {
	.product-grid-item-content {
		width: 250px;
            span {
                font-size: 0.8rem;
            }
            .product-description {
                font-size: 0.8rem;
        }
	}
		.product-grid-item-bottom {
			.product-price {
				align-self: center;
				font-size: 1rem;
			}
		}	
	}
}
</style>
